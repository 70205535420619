<template>
	<ion-page>
		<A-header title="Register" />
		
		<ion-content :fullscreen="true">
			<A-collapse-header title="Register" />

			<ion-item>
				<ion-label position="floating">Email</ion-label>
				<ion-input :autofocus="true" :clearInput="true" inputmode="email" v-model="form.email" enterkeyhint="next" @keydown.enter="$refs.password.$el.setFocus()"></ion-input>
			</ion-item>

			<ion-item>
				<ion-label position="floating">Password</ion-label>
				<ion-input type="password" :clearInput="true" :clearOnEdit="true" enterkeyhint="done" :min="8" v-model="form.password" @keydown.enter="submit()" ref="password"></ion-input>
			</ion-item>

			<p>Or you can <span @click="$router.replace({name: 'Login'})">login to your existing account</span></p>
		</ion-content>

		<ion-footer>
			<ion-toolbar>
				<p class="terms">By clicking register you agree to our<br><span class="link" @click="openTermsModal()">Terms and conditions</span></p>
				<ion-button expand="block" color="primary" fill="solid" enterkeyhint="done" @click="submit()">Register</ion-button>
			</ion-toolbar>
		</ion-footer>
	</ion-page>
</template>

<script>
import AHeader 				from '@/plugins/appshoppinglist/layout/a-header.vue'
import ACollapseHeader 		from '@/plugins/appshoppinglist/layout/a-collapse-header.vue'
import TermsModal 			from './terms-modal.vue'
import { toastController, modalController } 	from '@ionic/vue'

export default {
	components: {
		AHeader,
		ACollapseHeader
	},

	data() {
		return {
			form: {}
		}
	},

	methods: {
		async submit() {
			this.$wLoader.startLoading('Creating account...')

			try {
				await this.$store.dispatch('wAuth/signup', this.form)
				this.$router.replace({name: 'Lists'})
			} catch (error) {
				const toast = await toastController.create({
					message: error.data.error,
					duration: 2000,
					color: 'danger',
					position: 'bottom'
				})

				toast.present()
			}
			this.$wLoader.clearLoading()
		},

		async openTermsModal() {
			const modal = await modalController
				.create({
					component: TermsModal,
					componentProps: {
						title: 'New Title'
					}
				})
			return modal.present();
		}
	}
}
</script>

<style lang="sass" scoped>
ion-item
	--padding-start: 20px
	--padding-end: 20px
	--border-color: var(--ion-color-primary)

.terms
	text-align: center
	margin: 30px 0
	line-height: 23px

	.link
		color: var(--ion-color-primary)

p
	padding-left: 20px
	margin-top: 30px

	span
		color: var(--ion-color-primary)

ion-footer
	ion-toolbar
		--border-style: none !important
		--padding-bottom: 10px
</style>